img.frame-1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-1.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-1.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-1.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-2.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-2.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-2.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-3 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-3.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-3.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-3.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-4 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-4.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-4.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-4.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-5 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-5.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-5.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-5.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-6 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-6.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-6.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-6.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-7 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-7.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-7.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-7.webp) 30% round; /* Opera 11-12.1 */
}

img.frame-8 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frames/frame-8.webp) 30% round;
  -webkit-border-image: url(../../img/frames/frame-8.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frames/frame-8.webp) 30% round; /* Opera 11-12.1 */
}